
export var GET_METHOD = {

  GET_COUNTRY_LIST: '/api/admin/get_country_list',
  GET_SERVER_COUNTRY_LIST: '/admin/get_country_list',
  EBER_GET_SERVER_COUNTRY_LIST: '/get_country_list',
  DELIVERY_LIST: '/admin/delivery_list',

  //ServicesX 
  SERVICESX_GET_COUNTRY_LIST: '/api/demo/all_country_list',
  SERVICESX_GET_SERVICE_LIST: '/api/demo/service_list',
}


export var METHODS = {
  GET_COUNTRY_DATA: "/admin/get_country_data",
  CHECK_COUNTRY_EXISTS: '/api/admin/check_country_exists',
  CHECK_CITY: '/admin/check_city_available',
  GET_COUNTRY_TIMEZONE: '/admin/get_country_timezone',
  ADD_NEW_STORE: '/admin/add_new_store',
  ADD_NEW_USER: '/admin/add_new_user',
  ADD_NEW_PROVIDER: '/admin/add_new_provider',
  ADD_NEW_ADMIN: '/admin/add_new_admin',
  ADD_PROVIDER_VEHICLE_DATA: '/admin/add_provider_vehicle_data',
  GET_SERVICE_LIST_BY_CITY: "/admin/get_service_list_by_city",
  UPDATE_DATABASE_TABLE: '/updateDatabaseTable',
  UPDATE_ITEM_NEW_TABLE: '/updateItemNewTable',
  ADD_SERVICE_DATA: '/admin/add_service_data',


  // eber
  GET_TYPE_LIST: '/type_list',
  EBER_GET_COUNTRY_DATA: "/fetch_country_detail",
  EBER_CHECK_COUNTRY_EXISTS: '/check_country_exists',
  EBER_CHECK_CITY: '/check_city',
  EBER_GET_COUNTRY_TIMEZONE: '/get_country_timezone',
  EBER_ADD_NEW_USER: '/userregister',
  EBER_ADD_NEW_ADMIN: '/admin/add_new_admin_details',
  EBER_ADD_NEW_PROVIDER: '/providerregister',
  EBER_ADD_PROVIDER_VEHICLE_DATA: '/provider_add_vehicle',
  ADD_NEW_CORPORATE: '/add_detail',
  ADD_NEW_PARTNER: '/admin/add_new_partner',
  ADD_NEW_DISPATCHER: '/admin/add_new_dispatcher',
  ADD_NEW_HOTEL: '/admin/add_new_hotel',
  CHECK_SERVICE_TYPE: '/check_service_type',

  //eservice
  GET_SERVICE_LIST: '/api/admin/get_service_list',
  ESERVICE_GET_COUNTRY_DATA: '/api/get_country_data_new',
  ESERVICE_CHECK_COUNTRY_EXISTS: '/api/check_country_exists',
  ESERVICE_CHECK_CITY: '/api/check_city',
  ESERVICE_GET_COUNTRY_TIMEZONE: '/api/get_country_timezone',
  ESERVICE_CHECK_SERVICE_TYPE: '/api/check_service_type',
  ESERVICE_ADD_NEW_USER: '/api/user/register',
  ESERVICE_ADD_NEW_PROVIDER: '/api/provider/register',
  ESERVICE_ADD_NEW_COMPANY: '/api/company/signup',
  HUBSPOT_INTEGRATION: 'https://demo.elluminatiinc.net/demo_to_hubspot_integration',

  // serviceX  
  SERVICESX_CHECK_COUNTRY_EXISTS: '/api/demo/check_country_exist',
  SERVICESX_CHECK_CITY_EXISTS: '/api/demo/check_city_exist',
  SERVICESX_CUSTOMER_SIGNUP: '/api/demo/customer/signup',
  SERVICESX_PROVIDER_SIGNUP: '/api/demo/provider/signup',
  SERVICESX_UPDATE_CITY_SETTING: '/api/demo/city_setting',
  SERVICESX_CHECK_ADMIN_EXISTS: '/api/demo/check_admin_exist',
}
